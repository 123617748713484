import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { EventsSliderComponent } from '@pirexpo/features/sliders/events-slider';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { Person } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { SpeakerPopupComponentStore } from './speaker.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    MatDialogModule,
    FlexLayoutModule,
    TextButtonComponent,
    SvgIconComponent,
    BadgeComponent,
    EventsSliderComponent,
  ],
  providers: [SpeakerPopupComponentStore],
  selector: 'pirexpo-speaker-popup',
  standalone: true,
  styleUrls: ['./speaker-popup.component.scss'],
  templateUrl: './speaker-popup.component.html',
})
export class SpeakerPopupComponent implements OnInit {
  readonly events$$ = this.componentStore.events$$;
  readonly fallbackImage = '/assets/images/speaker-fallback.png';
  readonly speaker$$ = this.componentStore.speaker$$;
  readonly uniqueThemesId$$ = this.componentStore.uniqueThemesId$$;

  @Input() value!: Person['id'];

  constructor(private componentStore: SpeakerPopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getSpeakerDetailed(this.value);
  }
}
