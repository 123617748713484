<div class="social-box">
  <div class="social-box__container">
    <div class="social-box__inner-container w100">
      <img
        loading="lazy"
        class="social-box__logo"
        src="/assets/logo/{{ socialNetworkDetail.logo }}"
      />

      <div class="social-box__social-group w100">
        <div class="social-box__list">
          @for (item of socialNetworkDetail.socialNetworks; track item.name) {
            <a
              target="_blank"
              class="social-box__social-network"
              [href]="item.link"
            >
              <svg-icon
                class="social-box__social-network-icon"
                [applyClass]="true"
                [src]="'assets/icons/' + (item.name | lowercase) + '.svg'"
              />
            </a>
          }
        </div>

        @if (!hideCheckbox) {
          <mat-checkbox
            class="social-box__checkbox"
            [disableRipple]="'true'"
            [checked]="value"
            (change)="onChange($event.checked)"
          >
            Рассылка
          </mat-checkbox>
        }
      </div>
    </div>
  </div>
</div>
