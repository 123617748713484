<mat-dialog-actions>
  <pirexpo-circle-button
    class="popup__close-btn"
    type="download"
    (click)="close()"
  >
    <svg-icon class="close-icon" src="/assets/icons/close.svg" />
  </pirexpo-circle-button>
</mat-dialog-actions>
<mat-dialog-content class="video-popup-content video-popup">
  <iframe
    class="youtube-iframe"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    [src]="youtubeUrl$$() | safeSource: 'resourceUrl'"
  ></iframe>
</mat-dialog-content>
