<ngx-loading-bar [color]="'#da2b3e'" [includeSpinner]="false" />

<pirexpo-menu />

<div class="app-container" pirexpoScreenWidth>
  <pirexpo-header [invert]="specificRoute$$()" />

  <main class="app-content" [class.app-content_no-margin]="specificRoute$$()">
    <router-outlet />
  </main>

  <pirexpo-footer class="app-footer" />
</div>
