import { AsyncPipe, KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { PopupActions } from '@pirexpo/entities/popup';
import { EventsSliderComponent } from '@pirexpo/features/sliders/events-slider';
import { getSliderName, getTimeInterval } from '@pirexpo/shared/helpers';
import { WordDeclensionPipe } from '@pirexpo/shared/libs';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { CardPartnerComponent } from '@pirexpo/ui/cards/card-partner';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { Area, Partner } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { AreaPopupComponentStore } from './area-popup.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    KeyValuePipe,
    WordDeclensionPipe,
    MatDialogModule,
    FlexLayoutModule,
    BadgeComponent,
    TextButtonComponent,
    EventsSliderComponent,
    SvgIconComponent,
    CardPartnerComponent,
  ],
  providers: [AreaPopupComponentStore],
  selector: 'pirexpo-area-popup',
  standalone: true,
  styleUrls: ['./area-popup.component.scss'],
  templateUrl: './area-popup.component.html',
})
export class AreaPopupComponent implements OnInit {
  readonly area$$ = this.componentStore.area$$;
  readonly events$$ = this.componentStore.events$$;
  readonly partners$$ = this.componentStore.partners$$;

  @Input() value!: Area['id'];

  constructor(
    private store: Store,
    private componentStore: AreaPopupComponentStore,
  ) {}

  getSliderName(day: string, index: number): string {
    return getSliderName(day, index);
  }

  getTimeInterval(index: number): string {
    return getTimeInterval(index);
  }

  ngOnInit(): void {
    if (this.value) this.componentStore.getAreaDetailed(this.value);
  }

  openPartnerPopup(partner: Partner): void {
    this.store.dispatch(
      PopupActions.openPartnerPopup({ partnerId: partner.id }),
    );
  }
}
