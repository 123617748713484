import { LowerCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SvgIconComponent } from 'angular-svg-icon';

import { SocialNetworkDetail } from '../model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LowerCasePipe,
    MatCheckboxModule,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SocialBoxComponent),
    },
  ],
  selector: 'pirexpo-social-box',
  standalone: true,
  styleUrls: ['./social-box.component.scss'],
  templateUrl: './social-box.component.html',
})
export class SocialBoxComponent implements ControlValueAccessor {
  private _value = false;
  @Input() hideCheckbox = false;
  @Input() socialNetworkDetail!: SocialNetworkDetail;
  @Input() set value(v: boolean) {
    this._value = v;
    this.onChange(this._value);
  }

  get value(): boolean {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, perfectionist/sort-classes
  onChange(value: boolean): void {}
  // eslint-disable-next-line perfectionist/sort-classes
  onTouched = (): void => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }
}
