import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageService } from '@pirexpo/shared/api';
import { PIREXPO_CONFIG } from '@pirexpo/shared/app-config';
import { Observable } from 'rxjs';

export function HttpHeadersInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const tokenKey = inject(PIREXPO_CONFIG).TOKEN_KEY;
  const authToken = inject(LocalStorageService).getItem(tokenKey);

  if (authToken) {
    const authRequest = request.clone({
      headers: request.headers.set('visitor', authToken),
    });
    return next(authRequest);
  }
  return next(request);
}
