<mat-dialog-content class="popup greeting-popup">
  <div class="popup__top-container">
    <!-- <div>
      <text-button mat-dialog-close class="popup__back-btn color-dark-gray1">
        <icon class="popup__back-icon" [iconName]="'back'"></icon>
        <span>Назад</span>
      </text-button>
    </div> -->
    <p class="greeting-popup__date" fxHide.gt-xs>
      {{ value.date | date: 'd MMMM y' }}
    </p>
    <div
      class="greeting-popup__person"
      fxLayout="row"
      fxLayoutAlign="flex-start flex-start"
      fxLayoutAlign.xs="flex-start center"
    >
      <img
        class="greeting-popup__person-img"
        loading="lazy"
        [src]="value.photo"
        [alt]="value.title"
      />
      <div class="greeting-popup__person-info">
        <p class="greeting-popup__date" fxHide.xs>
          {{ value.date | date: 'd MMMM y' }}
        </p>
        <p class="greeting-popup__name">{{ value.title }}</p>
        <p class="greeting-popup__status">{{ value.status }}</p>
      </div>
    </div>
  </div>
  <div class="popup__middle-container">
    <div class="greeting-popup__description">
      <p
        *ngIf="value.description; else documentImage"
        class="greeting-popup__description-text"
        [innerHtml]="value.description"
      ></p>
      <ng-template #documentImage>
        <img
          class="greeting-popup__description-img"
          loading="lazy"
          [src]="value.document_image"
          [alt]="value.title + value.status"
        />
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
