import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { News } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { NewsPopupComponentStore } from './news-popup.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    BadgeComponent,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [NewsPopupComponentStore],
  selector: 'pirexpo-news-popup',
  standalone: true,
  styleUrls: ['./news-popup.component.scss'],
  templateUrl: './news-popup.component.html',
})
export class NewsPopupComponent implements OnInit {
  readonly loading$$ = this.componentStore.loading$$;
  readonly news$$ = this.componentStore.news$$;

  @Input() value!: News['id'];

  constructor(private componentStore: NewsPopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getNewsDetailed(this.value);
  }
}
