import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { EventsSliderComponent } from '@pirexpo/features/sliders/events-slider';
import { getSliderName, getTimeInterval } from '@pirexpo/shared/helpers';
import { WordDeclensionPipe } from '@pirexpo/shared/libs';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { Compilation } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { CompilationPopupComponentStore } from './compilation-popup.compoenent.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    KeyValuePipe,
    WordDeclensionPipe,
    MatDialogModule,
    FlexLayoutModule,
    TextButtonComponent,
    EventsSliderComponent,
    BadgeComponent,
    SvgIconComponent,
  ],
  providers: [CompilationPopupComponentStore],
  selector: 'pirexpo-compilation-popup',
  standalone: true,
  styleUrls: ['./compilation-popup.component.scss'],
  templateUrl: './compilation-popup.component.html',
})
export class CompilationPopupComponent implements OnInit {
  readonly compilation$$ = this.componentStore.compilation$$;
  readonly events$$ = this.componentStore.events$$;

  @Input() value!: Compilation['id'];

  constructor(private componentStore: CompilationPopupComponentStore) {}

  getSliderName(day: string, index: number): string {
    return getSliderName(day, index);
  }

  getTimeInterval(index: number): string {
    return getTimeInterval(index);
  }

  ngOnInit(): void {
    if (this.value) this.componentStore.getCompilationDetailed(this.value);
  }
}
