<footer *transloco="let t; read: 'layout'" class="footer">
  <div class="container">
    <div class="footer__content">
      <div class="footer__content-group">
        <div class="footer__block">
          <p class="footer__block-title">
            {{ t('footer.blockOne.title') | capitalize }}
          </p>

          <ul class="footer__list">
            <li class="footer__block-item">
              <a
                class="footer__block-link"
                [routerLink]="[ROUTE_TOKENS.CONTACTS]"
                >{{ t('footer.blockOne.officeContacts') | capitalize }}</a
              >
            </li>

            <li class="footer__block-item">
              <a
                class="footer__block-link"
                [routerLink]="[ROUTE_TOKENS.EXHIBITOR.EXHIBITOR]"
                >{{ t('footer.blockOne.forExhibitor') | capitalize }}</a
              >
            </li>
          </ul>
        </div>

        <div class="footer__block">
          <p class="footer__block-title">
            {{ t('footer.blockTwo.title') | capitalize }}
          </p>

          <ul class="footer__list">
            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://bestchefs.ru/"
                class="footer__block-link"
                >{{ t('footer.blockTwo.bestchefs') | capitalize }}</a
              >
            </li>

            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://cakeshow.ru/"
                class="footer__block-link"
                >{{ t('footer.blockTwo.cakeshow') | capitalize }}</a
              >
            </li>

            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://pizzachamp.ru/"
                class="footer__block-link"
                >{{ t('footer.blockTwo.pizzaChampionship') }}</a
              >
            </li>

            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://award.pirexpo.com/"
                class="footer__block-link"
                >{{ t('footer.blockTwo.awards') }}</a
              >
            </li>
          </ul>
        </div>

        <div class="footer__block">
          <p class="footer__block-title"></p>

          <ul class="footer__list">
            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://moscowcoffeefestival.com/"
                class="footer__block-link"
                >{{ t('footer.blockThree.coffeeFestival') | titlecase }}</a
              >
            </li>

            <li class="footer__block-item">
              <a
                target="_blank"
                rel="noopener"
                href="https://forum.pirexpo.com/"
                class="footer__block-link"
                >{{ t('footer.blockThree.frr') | titlecase }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <mat-accordion class="footer__accordion" [multi]="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ t('footer.blockOne.title') | capitalize }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <ul class="footer__list">
              <li class="footer__block-item">
                <a
                  class="footer__block-link"
                  [routerLink]="[ROUTE_TOKENS.CONTACTS]"
                  >{{ t('footer.blockOne.officeContacts') | capitalize }}</a
                >
              </li>
              <li class="footer__block-item">
                <a
                  class="footer__block-link"
                  [routerLink]="[ROUTE_TOKENS.EXHIBITOR.EXHIBITOR]"
                  >{{ t('footer.blockOne.forExhibitor') | capitalize }}</a
                >
              </li>
            </ul>
          </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ t('footer.blockTwo.title') | capitalize }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <ul class="footer__list">
              <li class="footer__block-item">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://bestchefs.ru/"
                  class="footer__block-link"
                  >{{ t('footer.blockTwo.bestchefs') | capitalize }}</a
                >
              </li>
              <li class="footer__block-item">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://cakeshow.ru/"
                  class="footer__block-link"
                  >{{ t('footer.blockTwo.cakeshow') | capitalize }}</a
                >
              </li>
              <li class="footer__block-item">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://pizzachamp.ru/"
                  class="footer__block-link"
                  >{{ t('footer.blockTwo.pizzaChampionship') }}</a
                >
              </li>
              <li class="footer__block-item">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://award.pirexpo.com/"
                  class="footer__block-link"
                  >{{ t('footer.blockTwo.awards') }}</a
                >
              </li>
            </ul>
          </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ t('footer.socialNetworks') | capitalize }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div>
              <p class="footer__item-title">
                Подпишитесь на наши соцсети и рассылки!
              </p>
              <p class="footer__item-sub-title">
                Самые важные новости для бизнес-сообщества и нужные полезные
                материалы!
              </p>
              <form
                #formGroupDirective="ngForm"
                [formGroup]="subscribeForm"
                (ngSubmit)="subscribe()"
              >
                <div
                  class="footer__networks-list footer__networks-list--highlighted"
                  fxLayout="column"
                >
                  @for (
                    socialNetworkDetail of socialNetworksList;
                    track socialNetworkDetail.formControlName
                  ) {
                    @if (socialNetworkDetail.highlighted) {
                      <pirexpo-social-box
                        class="footer__social-box"
                        [socialNetworkDetail]="socialNetworkDetail"
                        [formControlName]="socialNetworkDetail.formControlName"
                      />
                    }
                  }
                </div>
                <div class="footer__networks-list">
                  @for (
                    socialNetworkDetail of socialNetworksList;
                    track socialNetworkDetail.formControlName
                  ) {
                    @if (!socialNetworkDetail.highlighted) {
                      @if (socialNetworkDetail.formControlName) {
                        <pirexpo-social-box
                          class="footer__social-box footer__social-box-custom"
                          [socialNetworkDetail]="socialNetworkDetail"
                          [formControlName]="
                            socialNetworkDetail.formControlName
                          "
                        />
                      } @else {
                        <pirexpo-social-box
                          class="footer__social-box footer__social-box-custom"
                          [socialNetworkDetail]="socialNetworkDetail"
                          [hideCheckbox]="true"
                        />
                      }
                    }
                  }
                </div>
                <div class="footer__subscribe-group">
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input
                      autocomplete="email"
                      type="email"
                      matInput
                      name="email"
                      formControlName="email"
                    />
                  </mat-form-field>
                  <pirexpo-button
                    class="footer__subscribe-button"
                    size="middle"
                    text="Подписаться"
                    type="submit"
                    fullWidth
                    [loading]="loading$$()"
                  />
                </div>
              </form>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="footer__contacts">
        <pirexpo-outline-button
          class="footer__social-button"
          size="small"
          color="red"
          iconPosition="after"
          [text]="t('footer.socialNetworks') | capitalize"
          (click)="openSocialPopup()"
        >
          <svg-icon src="assets/icons/arrow-up-right.svg" />
        </pirexpo-outline-button>
        <div class="footer__contacts-group">
          <p class="footer__contacts-title">
            {{ t('footer.commonQuestions') | capitalize }}
          </p>

          <a class="footer__contacts-link" href="tel:+74956379440"
            >+7 (495) 637 94 40</a
          >

          <a href="mailto:info@pirexpo.com" class="footer__contacts-link">
            info&#64;pirexpo.com
          </a>
        </div>
        <div class="footer__contacts-group">
          <p class="footer__contacts-title">
            {{ t('footer.siteQuestions') | capitalize }}
          </p>

          <a href="mailto:support@pirexpo.com" class="footer__contacts-link"
            >support&#64;pirexpo.com</a
          >
        </div>
      </div>
    </div>
    <div class="footer__copyright-mobile">
      <span class="footer__copyright-text"
        >&copy; {{ t('footer.pir') }}, 1997-{{ currentYear }}</span
      >

      <div class="footer__copyright-text">
        Design by <span class="fw-600">Beta</span>
      </div>
    </div>
    <div class="footer__bottom-content">
      <div class="footer__bottom-group">
        <span class="footer__bottom-element"
          >&copy; {{ t('footer.pir') }}, 1997-{{ currentYear }}</span
        >

        <a
          class="footer__bottom-link footer__bottom-element"
          target="_blank"
          href="/assets/files/Dogovor_okazaniya_uslug_PIR_EXPO_2024_oferta.pdf"
          >{{ t('footer.publicOffer') | capitalize }}</a
        >

        <a
          class="footer__bottom-link footer__bottom-element"
          target="_blank"
          href="/assets/files/PIR_Expo_personal_data_processing_agreement2024.pdf"
          >{{ t('footer.agreement') | capitalize }}</a
        >

        <span class="footer__bottom-element">18+</span>
      </div>
      <div class="footer__bottom-design">
        Design by <span class="fw-600">Beta</span>
      </div>
    </div>
  </div>
</footer>
