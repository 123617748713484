import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ViewChild,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormGroupDirective,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, provideTranslocoScope } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { PopupActions } from '@pirexpo/entities/popup';
import { SocialBoxComponent } from '@pirexpo/features/forms/social-box';
import { ROUTE_TOKENS, SOCIAL_NETWORKS } from '@pirexpo/shared/app-config';
import { CapitalizePipe } from '@pirexpo/shared/libs';
import { ButtonComponent } from '@pirexpo/ui/buttons/button';
import { OutlineButtonComponent } from '@pirexpo/ui/buttons/outline-button';
import { Subscribe } from '@swagger/models';
import { SubscriptionsService } from '@swagger/services/subscriptions.service';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    CapitalizePipe,
    TitleCasePipe,
    TranslocoDirective,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    OutlineButtonComponent,
    SvgIconComponent,
    SocialBoxComponent,
    ButtonComponent,
  ],
  providers: [provideTranslocoScope('layout')],
  selector: 'pirexpo-footer',
  standalone: true,
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  readonly ROUTE_TOKENS = ROUTE_TOKENS;
  readonly currentYear = new Date().getFullYear();

  @ViewChild('formGroupDirective')
  formGroupDirective!: FormGroupDirective;

  readonly loading$$ = signal<boolean>(false);
  readonly socialNetworksList = SOCIAL_NETWORKS;

  readonly subscribeForm = this.formBuilder.group({
    bestchefs: [false],
    cakeshow: [false],
    coffee: [false],
    email: ['', [Validators.email, Validators.required]],
    hotel: [false],
    pizza: [false],
    restaurant: [false],
  });

  constructor(
    private store: Store,
    private formBuilder: NonNullableFormBuilder,
    private subscriptionsService: SubscriptionsService,
    private snackbar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {}

  openSocialPopup(): void {
    this.store.dispatch(PopupActions.openSocialNetworksPopup());
  }

  subscribe(): void {
    if (!this.subscribeForm.valid) return;

    this.loading$$.set(true);
    const form = <Subscribe>this.subscribeForm.value;

    for (const key in form) {
      if (
        Object.prototype.hasOwnProperty.call(form, key) &&
        !form[key as keyof Subscribe]
      ) {
        delete form[key as keyof Subscribe];
      }
    }

    this.subscriptionsService
      .subscriptionsSubscribeCreate(form)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.snackbar.open('Форма успешно отправлена'),
        // eslint-disable-next-line perfectionist/sort-objects
        error: () => {
          this.loading$$.set(false);
          this.snackbar.open('Произошла ошибка при отправке формы');
        },
        // eslint-disable-next-line perfectionist/sort-objects
        complete: () => {
          this.loading$$.set(false);
          this.subscribeForm.reset();
          this.formGroupDirective.resetForm();
        },
      });
  }
}
