import { Injectable, computed } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  EventDetailSerializerV2,
  EventSpeaker,
  EventThematic,
} from '@swagger/models';
import { EventService } from '@swagger/services/event.service';
import { EventThematicService } from '@swagger/services/event-thematic.service';
import { EMPTY, Observable, forkJoin } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

interface EventPopupState {
  error?: unknown;
  event?: EventDetailSerializerV2;
  eventThematics?: EventThematic[];
  loading?: boolean;
}

@Injectable()
export class EventPopupComponentStore extends ComponentStore<EventPopupState> {
  private readonly eventThematics$$ = this.selectSignal(
    (state) => state.eventThematics,
  );

  readonly error$$ = this.selectSignal((state) => state.error);

  readonly event$$ = this.selectSignal((state) => state.event);

  readonly eventThematicsNames$$ = computed(() =>
    this.eventThematics$$()?.map((thematic) => thematic.name),
  );

  readonly freeEntrance$$ = computed(
    () =>
      (!this.event$$()?.ticket_type || !this.event$$()?.registration) &&
      !this.event$$()?.moderation,
  );

  readonly getEventDetailed = this.effect(
    (eventId$: Observable<EventDetailSerializerV2['id']>) => {
      return eventId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((eventId) =>
          this.eventService.eventRead(String(eventId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (event) => this.patchState({ event, loading: false }),
            }),
          ),
        ),
        switchMap((event) => {
          if (!event.thematics) return EMPTY;

          const requests = event.thematics.map((thematicId) =>
            this.eventThematicService.eventThematicRead(String(thematicId)),
          );
          return forkJoin(requests).pipe(
            tap({
              error: (error: unknown) => this.patchState({ error }),
              next: (eventThematics) => this.patchState({ eventThematics }),
            }),
            catchError(() => EMPTY),
          );
        }),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  readonly paidEvent$$ = computed(
    () => this.event$$()?.registration && !!this.event$$()?.ticket_type?.price,
  );

  readonly preRegistration$$ = computed(
    () =>
      (this.event$$()?.registration && !this.event$$()?.ticket_type?.price) ||
      this.event$$()?.moderation,
  );

  readonly soldOut$$ = computed(() => this.event$$()?.ticket_type?.sold_out);

  readonly speakers$$ = computed(() =>
    this.event$$()?.speakers.map((speaker) => speaker.person),
  );

  readonly speakersByType$$ = computed(() =>
    this.event$$()?.speakers.reduce((acc, item) => {
      const typeKey = item?.type?.id || 'default';

      if (!acc.has(typeKey)) {
        acc.set(typeKey, []);
      }
      acc.get(typeKey)?.push(item);

      return acc;
    }, new Map<number | string, EventSpeaker[]>()),
  );

  constructor(
    private eventService: EventService,
    private eventThematicService: EventThematicService,
  ) {
    super({});
  }
}
