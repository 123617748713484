import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Brand } from '@swagger/models';
import { BrandService } from '@swagger/services/brand.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface ExhibitorPopupState {
  brand?: Brand;
  error?: unknown;
  loading?: boolean;
}

@Injectable()
export class ExhibitorPopupComponentStore extends ComponentStore<ExhibitorPopupState> {
  readonly brand$$ = this.selectSignal((state) => state.brand);

  readonly error$$ = this.selectSignal((state) => state.error);

  readonly getBrandDetailed = this.effect(
    (brandId$: Observable<Brand['id']>) => {
      return brandId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((brandId) =>
          this.brandService.brandRead(String(brandId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (brand) => this.patchState({ brand, loading: false }),
            }),
          ),
        ),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  constructor(private brandService: BrandService) {
    super({});
  }
}
