import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import * as i1 from '@ngx-formly/core';
import { ɵreverseDeepMerge, FORMLY_CONFIG, FormlyConfig, FormlyModule } from '@ngx-formly/core';
class PresetSubstitutionExtension {
  constructor(formlyConfig) {
    this.formlyConfig = formlyConfig;
  }
  prePopulate(field) {
    if (!(typeof field.type === 'string') || field.type[0] !== '#') {
      return;
    }
    const configId = new RegExp(/^#(.+)$/).exec(field.type)?.[1];
    const preset = this.formlyConfig.presets[configId];
    const {
      type: _,
      ...fieldConfigWithoutType
    } = field;
    if (preset) {
      const merged = ɵreverseDeepMerge(fieldConfigWithoutType, 'getConfiguration' in preset ? preset.getConfiguration() : preset);
      Object.assign(field, merged);
    }
  }
}
function registerLibraryConfigReplacementExtension(formlyConfig) {
  return {
    extensions: [{
      name: 'libraryConfigReplacement',
      extension: new PresetSubstitutionExtension(formlyConfig),
      priority: -300
    }]
  };
}
class FormlyPresetModule {}
FormlyPresetModule.ɵfac = function FormlyPresetModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyPresetModule)();
};
FormlyPresetModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyPresetModule
});
FormlyPresetModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: FORMLY_CONFIG,
    useFactory: registerLibraryConfigReplacementExtension,
    deps: [FormlyConfig],
    multi: true
  }],
  imports: [[FormlyModule.forChild({})]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyPresetModule, [{
    type: NgModule,
    args: [{
      imports: [FormlyModule.forChild({})],
      providers: [{
        provide: FORMLY_CONFIG,
        useFactory: registerLibraryConfigReplacementExtension,
        deps: [FormlyConfig],
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyPresetModule };
