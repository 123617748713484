import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { SanitizePipe } from '@pirexpo/shared/libs';
import { CircleButtonComponent } from '@pirexpo/ui/buttons/circle-button';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SanitizePipe,
    MatDialogModule,
    CircleButtonComponent,
    SvgIconComponent,
  ],
  selector: 'pirexpo-video-popup',
  standalone: true,
  styleUrls: ['./video-popup.component.scss'],
  templateUrl: './video-popup.component.html',
})
export class VideoPopupComponent implements OnInit {
  readonly youtubeUrl$$ = signal<string>('');

  constructor(
    private dialogRef: MatDialogRef<VideoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private youtubeVideoId: string,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const url = `https://www.youtube.com/embed/${this.youtubeVideoId}`;
    this.youtubeUrl$$.set(url);
  }
}
