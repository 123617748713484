import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { Partner } from '@swagger/models';
import { SvgIconComponent } from 'angular-svg-icon';

import { PartnerPopupComponentStore } from './partner.component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [PartnerPopupComponentStore],
  selector: 'pirexpo-partner-page-popup',
  standalone: true,
  styleUrls: ['./partner-page-popup.component.scss'],
  templateUrl: './partner-page-popup.component.html',
})
export class PartnerPagePopupComponent implements OnInit {
  readonly partner$$ = this.componentStore.partner$$;

  @Input() value!: Partner['id'];

  constructor(private componentStore: PartnerPopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getPartnerDetailed(this.value);
  }
}
