import { DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { Greeting } from '@swagger/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DatePipe, MatDialogModule, FlexLayoutModule],
  selector: 'pirexpo-greeting-popup',
  standalone: true,
  styleUrls: ['./greeting-popup.component.scss'],
  templateUrl: './greeting-popup.component.html',
})
export class GreetingPopupComponent {
  @Input() value!: Greeting;
}
