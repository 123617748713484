import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { PersistStorageService, handleScrollOnNavigation } from '@pirexpo/core';
import { CartActions } from '@pirexpo/entities/cart';
import { FooterComponent } from '@pirexpo/layout/footer';
import { HeaderComponent } from '@pirexpo/layout/header';
import { MenuComponent } from '@pirexpo/layout/menu';
import {
  LocalStorageService,
  MenuService,
  ScreenWidthDirective,
} from '@pirexpo/shared/api';
import {
  PIREXPO_CONFIG,
  PirexpoConfig,
  YANDEX_API_KEY,
  yaMapConfig$,
} from '@pirexpo/shared/app-config';
import { GetVisitorTokenService } from '@swagger/services/get-visitor-token.service';
import { of } from 'rxjs';
import { filter, skip, switchMap, tap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    LoadingBarModule,
    ScreenWidthDirective,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
  ],
  selector: 'pirexpo-root',
  standalone: true,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  readonly specificRoute$$ = signal<boolean>(false);

  constructor(
    @Inject(PIREXPO_CONFIG) private readonly appConfig: PirexpoConfig,
    private store: Store,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private translocoService: TranslocoService,
    private menuService: MenuService,
    private localStorageService: LocalStorageService,
    private persistStorageService: PersistStorageService,
    private tokenService: GetVisitorTokenService,
    private destroyRef: DestroyRef,
  ) {
    this.persistStorageService.setPersistStorage();
    this.viewportScroller.setHistoryScrollRestoration('manual');
    handleScrollOnNavigation();
  }

  private getVisitorToken(): void {
    this.tokenService
      .getVisitorTokenList({
        shopId: <number>this.appConfig.SHOP_ID,
      })
      .pipe(
        tap((response) =>
          this.localStorageService.setItem(
            this.appConfig.TOKEN_KEY,
            response.key,
          ),
        ),
        switchMap(() => of(this.loadCart())),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        // eslint-disable-next-line no-console
        error: (error: unknown) => console.error(error),
      });
  }

  private handleLangChange(): void {
    this.translocoService.langChanges$
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (currLang) => {
          const lang = currLang === 'ru' ? 'ru_RU' : 'en_US';

          yaMapConfig$.next({ apikey: YANDEX_API_KEY, lang });
          this.localStorageService.setItem('lang', currLang);
        },
      });
  }

  private handleRouterEvents(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        skip(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        setTimeout(() => this.menuService.closeMenu());

        // TODO: порефачить вхождения роутов
        const specificRoute =
          event.url.includes('/theme') ||
          event.url.includes('/exposition') ||
          event.url.includes('/program') ||
          event.url.includes('/partners');

        this.specificRoute$$.set(specificRoute);
      });
  }

  private loadCart(): void {
    this.store.dispatch(CartActions.loadCart());
  }

  private setAppLang(): void {
    const lang = this.localStorageService.getItem('lang');
    this.translocoService.setActiveLang(lang ?? 'ru');
  }

  ngOnInit(): void {
    this.setAppLang();
    this.handleLangChange();
    this.handleRouterEvents();

    if (this.localStorageService.getItem(this.appConfig.TOKEN_KEY))
      this.loadCart();
    else this.getVisitorToken();
  }
}
