import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  computed,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { BoothPopup } from '@pirexpo/shared/model';
import { TextButtonComponent } from '@pirexpo/ui/buttons/text-button';
import { BadgeComponent } from '@pirexpo/ui/common/badge';
import { SvgIconComponent } from 'angular-svg-icon';

import { ExhibitorPopupComponentStore } from './exhibitor-popup-component.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    BadgeComponent,
    SvgIconComponent,
    TextButtonComponent,
  ],
  providers: [ExhibitorPopupComponentStore],
  selector: 'pirexpo-exhibitor-popup',
  standalone: true,
  templateUrl: './exhibitor-popup.component.html',
})
export class ExhibitorPopupComponent implements OnInit {
  readonly booth$$ = computed(() =>
    this.brand$$()?.booths?.find((b) => b.id === this.value.boothId),
  );

  readonly brand$$ = this.componentStore.brand$$;

  @Input() value!: BoothPopup;

  constructor(private componentStore: ExhibitorPopupComponentStore) {}

  ngOnInit(): void {
    if (this.value) this.componentStore.getBrandDetailed(this.value.brandId);
  }
}
