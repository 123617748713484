import { Injectable, computed } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  TIME_INTERVALS,
  createSliderConfigs,
  filterEventsByTime,
  filterPlainArray,
  prepareEventForTemplate,
} from '@pirexpo/shared/helpers';
import { PreparedEvent, TodoAny } from '@pirexpo/shared/model';
import { Compilation, CompilationDetail } from '@swagger/models';
import { CompilationService } from '@swagger/services/compilation.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface CompilationPopupState {
  compilation?: CompilationDetail;
  error?: unknown;
  loading?: boolean;
}

@Injectable()
export class CompilationPopupComponentStore extends ComponentStore<CompilationPopupState> {
  readonly compilation$$ = this.selectSignal((state) => state.compilation);

  readonly error$$ = this.selectSignal((state) => state.error);

  readonly events$$ = computed(() => {
    const daysArray: string[] = [];
    let uniqueDays: string[] = [];
    const processedEvents: PreparedEvent[] = [];
    const eventsByDay: TodoAny = {};

    this.compilation$$()?.events?.map((event: TodoAny) => {
      const processedEvent = prepareEventForTemplate(event);
      daysArray.push(`${processedEvent.day}`);
      processedEvents.push(processedEvent);
    });

    uniqueDays = [...new Set(daysArray)].sort((a, b) => (a > b ? 1 : -1));

    uniqueDays.forEach((day) => {
      const filteredEventsByDay: TodoAny = {};
      filteredEventsByDay[day] = filterPlainArray(processedEvents, {
        day: [day],
      });
      eventsByDay[day] = [];

      TIME_INTERVALS.forEach((interval, index) => {
        eventsByDay[day][index] = [
          ...filterEventsByTime(
            filteredEventsByDay[day],
            interval.timeStart,
            interval.timeFinish,
          ),
        ];
      });
    });

    createSliderConfigs(eventsByDay, {});

    return eventsByDay;
  });

  readonly getCompilationDetailed = this.effect(
    (compilationId$: Observable<Compilation['id']>) => {
      return compilationId$.pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap((compilationId) =>
          this.compilationService.compilationRead(String(compilationId)).pipe(
            tapResponse({
              error: (error: unknown) =>
                this.patchState({ error, loading: false }),
              next: (compilation) =>
                this.patchState({ compilation, loading: false }),
            }),
          ),
        ),
      );
    },
  );

  readonly loading$$ = this.selectSignal((state) => state.loading);

  constructor(private compilationService: CompilationService) {
    super({});
  }
}
